body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 100;
  color: rgb(0, 0, 0);
  height: 50px;
  border-bottom: 1px solid #000000;
  vertical-align:bottom;
}

.navbar a {
  color:#2e2d2d;
  padding:5px;
  text-decoration:none;
  font:14px Verdana;
}

.sideitem{
  padding:5px;
  font:16px Verdana;
  text-align:center;
}

.sideitem a{
  text-decoration:none;
  color:#000000;
}

.sideitemSel{  
  border-left:2px solid #0000ff;
}

.sidebar {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f0f1f5;
  padding-top: 100px;
  color: rgb(0, 0, 0);
  border-right: 1px solid #000000;
}

.container {
  margin-left: 210px; /* Adjust according to sidebar width */
  padding: 5px;
}

.container h1{
  font:36px sans-serif;
}

.container h3{
  font:30px sans-serif;
}

.gridData {
  border-collapse:collapse;
  width:100%;
}

.gridData td {
  font:12px Verdana;
  padding:5px;
}

.gridData th {
  font:bold 12px Verdana;
  padding:5px;
  background: #f4f3f3;
}

.gridData tr {
  border:1px solid #c0c0c0;
}

.gridData td a, .linkTd{
  color:#FAAF3B;
  text-decoration:none;
  font-weight: bold;
}

.gridDataNum {
  text-align:right;
}

.greenButton{
  margin:0 auto;
  padding:10px;
  background:#008000;
  color:white;
  width:150px;
  text-align:center;
  cursor:pointer;
}

.redButton{
  margin:0 auto;
  padding:10px;
  background:#d94444;
  color:white;
  width:150px;
  text-align:center;
  cursor:pointer;
}

.blueButton{
  margin:0 auto;
  padding:10px;
  background:#384df0;
  color:white;
  width:150px;
  text-align:center;
  cursor:pointer;
}

.blueButtonDisabled{
  margin:0 auto;
  padding:10px;
  background:#6e7fff;
  color:white;
  width:150px;
  text-align:center;
}

.formContainer {
  margin: 5px;    
  color:#404040;
}
.formContainer td {
  padding:5px;                    
}
.formContainer input, .formContainer select {
  font:14px Arial;
  padding:5px;
  border:1px solid #c0c0c0;
}
.formContainer .littleLabel {
  font:11px Arial;
}

.tipContainer {
  position: absolute;
  background-color: #504ca6;
  border: 1px solid #1c1e65;
  padding: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color:white;
  font:bold 12px Verdana;
  cursor:pointer;
}

dialog{
  border:1px solid #c0c0c0;
}

.dialogTitle {
  font:24px Arial;
}

.state_table {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;

  a {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 1px;
  }
  table th:first-child {
    border-radius: 5px 0 0 5px;
  }
  table th:last-child {
    border-radius: 0 5px 5px 0;
  }
  th {
    padding: 10px 10px;
    background: #E4E4E4;
    /* Grey */

    text-align: center;
    font-size: 11px;
  }
  tbody:before {
    content: "-";
    display: block;
    line-height: 1em;
    color: transparent;
  }
  td {
    border: solid 1px #E4E4E4;
    border-style: solid none;
    padding: 10px;
    background-color: white;
    text-align: center;
  }
  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  .green {
    color: #41B2AA;
  }
  .patient_score_dot_style {
    margin: 0 auto;
    display: inline-block;
    height: 12px;
    width: 12px;
    line-height: 12px;
    -moz-border-radius: 30px;
    /* or 50% */

    border-radius: 30px;
    /* or 50% */
  }
  .images_div {
    display: flex;
    flex-direction: row;
  }
  .close_iframe {
    background-color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    float: right;
  }
  .iframe_style {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 600px;
    background-color: white;
  }
  .iframe_display {
    display: none;
  }
}

.profile-list {
  padding: 20px;
  background: #fff;
  min-height: 250px;
  margin-top: 10px;
  margin-right: 5%;

}

.sticky_header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.pagination_groups {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.week_table {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 15px !important;

  input {
      top: 646px;
      left: 423px;
      width: 113px;
      height: 26px;
      /* UI Properties */
      text-align: left;
      font: normal normal bold 13px/20px Muli;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      font-size: 8px;
      border-radius:9px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
    }

  a {
    color: #50545A;
    padding: 2px 12px;
  }

  a:hover, a:active, a:visited, a:focus {
    color: black;
    background-color: transparent;
    text-decoration: none;
  }

  table {
    width: 100%;
    border-collapse: separate;    
  }

  th {
    padding: 10px 10px;
    border-top: solid 3px #707070;
    border-bottom: solid 3px #707070;

    text-align: center;
    font-size: 11px;
  }

  th:first-child {
    border-left: solid 3px #707070;
    border-right: solid 1px #707070;
  }

  th:last-child {
    border-right: solid 3px #707070;
  }

  tbody:before {    
    display: block;
    line-height: 1em;
    color: transparent;
  }

  tr {
    background-color: #F5F5F5;
    border-color: white;

  }

  tr:last-child {
      td {
        border-bottom: solid 3px #707070;
      }
  }

  td {
    border-top: solid 1px #ffffff;
    border-bottom: solid 1px #ffffff;
    padding: 1px;    
    text-align: center;
  }

  td:first-child {
    border-left: solid 3px #707070;
    border-right: solid 1px #707070;
  }

  td:last-child {
    border-right: solid 3px #707070;
  }

  .green {
    color: #24B474;
  }

  .blue {
    color: #5bc0de;
  }

  .patient_score_dot_style {
    margin: 0 auto;
    display: inline-block;
    height: 12px;
    width: 12px;
    line-height: 12px;
    -moz-border-radius: 30px;
    /* or 50% */

    border-radius: 30px;
    /* or 50% */
  }
  .images_div {
    display: flex;
    flex-direction: row;
  }
  .close_iframe {
    background-color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    float: right;
  }
  .iframe_style {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 600px;
    background-color: white;
  }
  .iframe_display {
    display: none;
  }

}

.udt-radio-button {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  input {
        top: 286px;
        left: 473px;
        width: 10%;
        height: 23px;
        /* UI Properties */
        text-align: left;
        font: normal normal bold 20px/25px Muli;
        letter-spacing: 0px;
        opacity: 1;
  }

  input:nth-child(2n) {
         margin-left: 10%;
  }
}

.th-no-button-line{
  border-bottom: none !important;
}

.new_user {
  div {
      label {
          margin-bottom: 0px;
          font-family: 'Open Sans', sans-serif;
          font-weight: normal;
      }
  }

  .form-group {
      margin-bottom: 5px;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.btn-primary {
  color: rgb(255, 255, 255);
  background-color: rgb(51, 122, 183);
  border-color: rgb(46, 109, 164);
}

.btn {
  display: inline-block;
  margin-bottom: 0px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  font-size: 14px;
  line-height: 1.42857;
  user-select: none;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  padding: 6px 12px;
  border-radius: 4px;
}

input[type="text" i] {
  padding-block: 1px;
  padding-inline: 2px;
}

input[type="password" i] {
  -webkit-text-security: disc !important;
  padding-block: 1px;
  padding-inline: 2px;
}

div.field, div.actions {
  margin-bottom: 10px;
}

h1, h2, h3, h4 {
  font-family: "Open Sans", sans-serif;
}

h2, .h2 {
  font-size: 30px;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* ensure it's above other elements */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}